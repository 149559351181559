<template>
    <div class="passwordS">
        <div class="loginS_log" style="font-size: 40px; font-weight: bold; color: rgb(204, 0, 0); padding: 20px;">
          <span style="font-size: 40px; font-weight: bold; color: #c00;">华品富商餐饮盈利系统</span>
            <!-- <img src="@img/logo.png" alt=""> -->
            <!-- <h2>餐添大数</h2> -->
        </div>
        <!-- <img class="referral_img" src="../../assets/img/referral.png" alt=""> -->
        <div class="loginS_box">
            <div class="loginS_left">
                <p>data</p>
                <div class="loginS_box_l">
                    <h3>{{tiem_day}}</h3>
                    <div>
                        <p>Medium</p>
                        <p>{{tiem_Medium}}</p>
                    </div>
                </div>
            </div>
            <div class="loginS_left">
                <p>time</p>
                <div class="loginS_box_l">
                    <h3>{{timerS}}</h3>
                    <div>
                        <p>Medium</p>
                        <p>{{timerS}}</p>
                    </div>
                </div>
            </div>
            <div class="loginS_rigth">
              <div class="top">
                <p @click="$router.back()">返回</p>
                <p>忘记密码</p>
              </div>
              <div class="register-box-s">
                <!--  :rules="rules" -->
                <el-form
                  :model="registerUser"
                  class="registerForm register-s"
                  ref="registerForm"
                  label-width="80px"
                >
                  <div class="registerForm-box">
                    <el-form-item label="" prop="phone" class="registe-k registe-p">
                      <i class="phone-i"></i>
                      <!-- @blur="phone_shi" -->
                      <el-input
                        v-model="registerUser.phone"
                        placeholder="请输入手机号"
                        maxlength="11"
                        onkeyup="value=value.replace(/[^\d]/g,'')"

                      ></el-input>
                    </el-form-item>
                    <el-form-item prop="验证码" class="code registe-k registe-code">
                      <el-input
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                        v-model="registerUser.sendcode"
                        placeholder="请输入验证码"
                      ></el-input>
                      <div class="Fsend">
                        <el-button
                          type="button"
                          @click="sendcode"
                          :disabled="disabled"
                          v-if="disabled == false"
                          >获取验证码
                        </el-button>
                        <!-- @click="sendcode" -->
                        <el-button
                          type="button"
                          :disabled="disabled"
                          v-if="disabled == true"
                          >{{ btntxt }}
                        </el-button>
                    </div>
                    </el-form-item>
                  </div>
                  <el-form-item label="" prop="password" class="registe-k">
                    <i class="phone-m"></i>
                    <el-input
                      :type="mima"
                      v-model="registerUser.password"
                      placeholder="请输入6-20位数字与字母组合密码"
                    ></el-input>
                    <i
                      @click="yanjing"
                      class="gai"
                      ><img
                        :src="
                          suo
                            ? require('../../assets/img/yb.png')
                            : require('../../assets/img/yz.png')
                        "
                        alt=""
                    /></i>
                  </el-form-item>
                  <el-form-item label="" prop="password2" class="registe-k">
                    <i class="phone-m"></i>
                    <el-input
                      :type="mimaa"
                      v-model="registerUser.password2"
                      placeholder="请再次确认"
                    ></el-input>
                    <i
                      @click="yanjing1"
                      class="gai"
                      ><img
                        :src="
                          suo1
                            ? require('../../assets/img/yb.png')
                            : require('../../assets/img/yz.png')
                        "
                        alt=""
                    /></i>
                  </el-form-item>
                  <el-form-item>
                    <!-- @click="submitForm('registerForm')" -->
                    <el-button
                      type="primary"
                      @click="submitForm()"
                      class="submit_btn"
                      >确 定</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getCode, forget_user_pwd } from '@api/pulic'
import '../../assets/css/elementuidef.scss'

export default {
  name: 'Register',
  data() {
    return {
      tiem_Medium:'',
      timerS: '',//定义一个定时器的变量
      currentTime: new Date(), // 获取当前时间
      tiem_Hours:'',
      visible2: true,
      mima: 'password', // 显示密码
      mimaa: 'password', // 显示密码
      suo: true, // 显示密码切换.
      suo1: true, // 显示密码切换.
      registerUser: {
        phone: '', // 手机
        sendcode: '', // 验证码
        // 密码
        password: '', // 1
        password2: '' // 2
      },
      disabled: false,
      time: 0,
      btntxt: '重新发送'
    }
  },
  methods: {
    yanjing() {
      this.suo = !this.suo
      if (this.suo) {
        this.mima = 'password'
      } else {
        this.mima = 'text'
      }
    },
    yanjing1() {
      this.suo1 = !this.suo1
      if (this.suo1) {
        this.mimaa = 'password'
      } else {
        this.mimaa = 'text'
      }
    },
    // 手机号 验证
    sendcode() {
      const reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/
      if (this.registerUser.phone === '') {
        this.$message({
          message: '手机号不能为空',
          test: 'success',
          center: true
        })
        return
      }
      if (!reg.test(this.registerUser.phone)) {
        this.$message({
          message: '请输入正确的手机号',
          center: true
        })
      } else {
        console.log(this.registerUser.phone)
        // this.$message({
        //   message: '发送成功',
        //   type: 'success',
        //   center: true
        // })
        this.time = 60
        this.disabled = true
        this.timer()
        this.sms_a()
      }
    },
    // 验证码
    sms_a() {
      getCode({
        data: {
          mobile: this.registerUser.phone
        }
      }).then(res => {
        console.log(res)
        if (res.code == 10000) {
          this.$message({
            message: '今日以超过最大值',
            type: 'warning'
          })
        } else if (res.code == 10001) {
          this.$message({
            message: '短信发送成功',
            type: 'success'
          })
        } else if (res.code == 10002) {
          this.$message({
            message: '短信发送失败',
            type: 'error'
          })
        }
      })
    },

    // 60S倒计时
    timer() {
      if (this.time > 0) {
        this.time--
        this.btntxt = this.time + 's后重新获取'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.btntxt = '获取验证码'
        this.disabled = false
      }
    },
    // 提交之后

    submitForm() {
      const regphone = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/
      const pass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/

      if (this.registerUser.phone === '') {
        this.$message({
          message: '手机号不能为空',
          type: 'error'
        })
        console.log(this.registerUser.password.length + 'asd')
      } else if (!regphone.test(this.registerUser.phone)) {
        this.$message({
          message: '请你输入正确的手机号',
          type: 'error'
        })
      } else if (this.registerUser.password === '') {
        this.$message({
          message: '新密码不能为空',
          type: 'error'
        })
      } else if (!pass.test(this.registerUser.password)) {
        this.$message({
          message: '请输入6-20位数字与字母组合密码',
          type: 'error'
        })
      } else if (this.registerUser.password.length < 6 && this.registerUser.password.length > 12) {
        this.$message({
          message: '长度在 6 到 12 个字符',
          type: 'error'
        })
      } else if (this.registerUser.password2 === '') {
        this.$message({
          message: '再次确认密码不能为空',
          type: 'error'
        })
      } else if (this.registerUser.password !== this.registerUser.password2) {
        this.$message({
          message: '密码需要一致',
          type: 'error'
        })
      } else if (this.registerUser.sendcode.length !== 4) {
        this.$message({
          message: '验证码错误',
          type: 'error'
        })
      } else {
        forget_user_pwd({
          data: {
            mobile: this.registerUser.phone,
            pwd: this.registerUser.password,
            sms: this.registerUser.sendcode
          }
        }).then(res => {
          console.log(res)
         
          if (res.code == 10013) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.$router.push('/')
          } else if (res.code == 10014) {
            this.$message({
              message: '修改密码失败',
              type: 'error'
            })
          } else if (res.code == 10112) {
            this.$message({
              message: '旧密码和新密码不能一致',
              type: 'warning'
            })
          }
           else if (res.code == 10005) {
             console.log(222)
            this.$message({
              message: '验证码错误',
              type: 'warning'
            })
          }
        })
      }
    },

    login() {
      this.$router.push('/')
    }
  },
  created: function() {
    var aData = new Date();
    // 获取当前月日
    this.tiem_Medium =  aData.getFullYear() + "年" + (aData.getMonth() + 1) + "月"       // 获取当前日期
    this.tiem_day =  aData.getDate();
     if(aData.getMinutes() <= 9){
        this.timerS = aData.getHours() + ':' + 0 + aData.getMinutes()
      } else{
        this.timerS = aData.getHours() + ':' + aData.getMinutes()
      }
  }
}
</script>

<style scoped lang="scss">
.passwordS .registerForm-box .registe-code{
  width: 53%;
}
.passwordS{
    position: relative;
    background-image: url(../../assets/img/toRegister.png);
    background-size: 100% 100%;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;

    .referral_img{
      position: absolute;
      left: 5%;
      top: 46%;
      transform: translate(0,-50%);
    }

    .loginS_log{
        h2{
            font-size: 34px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
        img{
            margin: 40px 0 0 40px;
        }
    }
    .loginS_box{
        width: 80%;
        height: 200px;
        position: fixed;
        left: 50%;
        transform: translate(-50%);
        bottom: 40px;
        background: #2D4662;
        border-radius: 24px;
        display: flex;
        padding: 30px 30px;
        box-sizing: border-box;
        .loginS_left{
            margin-right: 200px;
            & > p{
                font-size: 22px;
                font-weight: 500;
                color: #75A4D9;
                margin-bottom: 26px;
            }
            .loginS_box_l{
                display: flex;
                 h3{
                    font-size: 70px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                     margin-right: 18px;
                 }
                div{
                    width: 150px;
                    margin-top: 10px;
                    p{
                        font-size: 22px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #75A4D9;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .loginS_rigth{
          background: rgba(45, 70, 98, .5);
          border-radius: 20px;
          position: absolute;
          right: 0;
          bottom: 0;
          width: 390px;
          margin: 0 auto;
          padding: 20px;
          >.top{
            font-size: 16px;
            display: inline-flex;
            justify-content:space-around;
            line-height: 40px;
            width: 70%;
            >p{
              color: #fff;
              &:last-of-type{
                color: #4AF0FF;
                font-size: 20px;
              }
            }

          }
          .register-box-s{
            width: 90%;
            margin: 0 auto;
          }
          .registe-k{
            position: relative;
            .gai{
              position: absolute;
              right: 16px;
              top: 5px;
            }
          }
          .privacy {
            p {
              color: #bababa;
              font-size: 16px;
              margin-top: 20px;

              span {
                color: #fc4353;
              }
            }
          }
          .register-z {
            margin-top: 14px;
            & :hover {
              cursor: pointer;
            }
            p {
              display: flex;
              span {
                color: #c5c5c5;
                font-size: 16px;
              }
              & span:last-of-type {
                color: #fc4353;
              }
              i {
                background: url('../../assets/img/deng.png') no-repeat;
                width: 28px;
                height: 26px;
                background-size: 20px;
                display: block;
                margin-left: 10px;
              }
            }
          }
        }

    }
}
</style>
